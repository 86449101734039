import { bindable } from 'aurelia-templating';
import { DocumentsApiClient, GetDocumentResponse } from '../../services/cyber-api';
import { autoinject } from 'aurelia-framework';
import { StateApi } from '../../services/state-api';
import { saveAs } from 'file-saver';

@autoinject()
export class Documents {
    @bindable() private siteId: string;
    private documents: GetDocumentResponse[];

    constructor(
        private documentsApi: DocumentsApiClient,
        private stateApi: StateApi
    ) {
    }

    private async bind(): Promise<void> {
        this.documents = await this.documentsApi.list(this.stateApi.company(), this.siteId);
    }

    private downloadDocument(documentId: string): void {
        this.documentsApi.download(documentId, this.stateApi.company(), this.siteId)
            .then((response) => {
                saveAs(response.data, response.fileName);
            });
    }
}
